import {action, observable} from 'mobx';
import {biLogger} from '../bi';
import {ModalResolveValues, setCurrentStepOnWorkerWithEditorSDK} from '../utils/editorSDKhelper';
import {TLanguage, IFlagUrls, TFlagLocaleTuple} from '../types/TLanguage';
import {OnBoardingApi} from '../services/onboarding-api';
import {IEditorSDK, IModalPanelOptions} from '../types/wix-types';
import {TypedExperiments} from '../types/TWixTypes';
import {extractDefaultCountryCode} from '../services/language-defaults-extractor';
import {FedopsLogger} from '@wix/fedops-logger';
import {EStep} from '../bi/params';
import {TranslationMethod} from '../types/common';

export class OnBoardingStore {
  private readonly token: string;
  private instanceId: string;
  private readonly onBoardingApi: OnBoardingApi;
  private languagesList: TLanguage[];
  private locale: string;
  private readonly editorSDK: IEditorSDK;
  private didChangeMainLang: boolean;
  private instance: string;

  public languagesIdList;
  @observable
  public mainLanguageId: string;
  @observable
  public secondaryLanguageId: string;
  @observable
  public mainLanguageLocaleId: string;
  @observable
  public secondaryLanguageLocaleId: string;
  @observable
  public secondaryLanguageAutoTranslate: string;
  @observable
  public secondaryLanguageMakeVisible: string;
  @observable
  public mainFlagCountryCode: string;
  @observable
  public secondaryFlagCountryCode: string;
  public fedopsLogger: FedopsLogger;
  public metaSiteId: string;
  public siteLanguage: string;
  public siteLocale: string;
  private returningUser: boolean = false;

  constructor(
    editorSDK: IEditorSDK,
    token: string,
    onBoardingApi: OnBoardingApi,
    fedopsLogger: FedopsLogger
  ) {
    this.editorSDK = editorSDK;
    this.token = token;
    this.onBoardingApi = onBoardingApi;
    this.fedopsLogger = fedopsLogger;
    this.secondaryLanguageId = '';
    this.secondaryLanguageLocaleId = '';
    this.mainFlagCountryCode = '';
    this.secondaryFlagCountryCode = '';
    this.didChangeMainLang = false;
    this.secondaryLanguageAutoTranslate = '';
    this.secondaryLanguageMakeVisible = '';

    this.editorSDK.addEventListener('instanceChanged', (e) => {
      this.updateInstance(e.detail.instance);
    });

    this.init();
  }

  private updateInstance(instance: string) {
    this.instance = instance;
    this.instanceId = OnBoardingStore.extractInstanceId(instance);
  }

  public async init() {
    const editorSessionId: string = await this.editorSDK.editor.info.getEditorSessionId();
    this.metaSiteId = await this.editorSDK.document.info.getMetaSiteId();
    biLogger.util.updateDefaults({esi: editorSessionId, msid: this.metaSiteId});
  }

  private async getLanguageAndLocaleFromEnv(envLocale: string) {
    let languageId = await this.onBoardingApi.getSiteLanguage(this.metaSiteId);
    if (languageId) {
      const localeId = this.getLocaleIdByLanguageId(languageId);
      return {
        languageId,
        localeId,
      };
    }
    languageId = this.getLanguageIdByLocale(envLocale);
    return {
      languageId,
      localeId: envLocale,
    };
  }

  private async getLanguageForMainLanguage(envLocale: string): Promise<{
    languageId: string;
    localeId: string;
  }> {
    const {
      data: {data},
    } = await this.onBoardingApi.getLanguagesStatus(this.instanceId);
    if (data?.originalLanguage.length) {
      this.returningUser = true;
      const [{code, locale}] = data.originalLanguage;
      return {
        languageId: code,
        localeId: locale,
      };
    }
    if (!data?.translationLanguages.length) {
      return this.getLanguageAndLocaleFromEnv(envLocale);
    }
  }

  public async loadData() {
    if (!this.metaSiteId) {
      throw new Error('msid is missing');
    }

    const languagesPromise = this.onBoardingApi.getLanguages();
    const localePromise = this.editorSDK.environment.getLocale();
    const instancePromise = this.editorSDK.info.getAppInstance();

    const [languages, locale, instance] = await Promise.all([
      languagesPromise,
      localePromise,
      instancePromise,
    ]);
    this.updateInstance(instance);

    this.languagesList = languages.data.data.languages;
    this.locale = locale;

    const languageAndLocaleForMain = await this.getLanguageForMainLanguage(locale);
    this.mainLanguageId = languageAndLocaleForMain.languageId;
    this.mainLanguageLocaleId = languageAndLocaleForMain.localeId;
    this.mainFlagCountryCode = this.getMainFlagCountryCode();
    this.languagesIdList = this.getLanguagesIdList();
  }

  private getLanguageFlagByLocale(language: TLanguage, localeId: string = language.defaultLocale) {
    const FlagTuple = language.completeFlagUrls.find(([locale]) => locale === localeId);
    if (FlagTuple) {
      return FlagTuple[1];
    }
    if (localeId !== language.defaultLocale) {
      return this.getLanguageFlagByLocale(language);
    }
    // eslint-disable-next-line no-console
    console.error(`can't find flag for language: ${language.code}, locale: ${localeId}`);
  }

  private getMainFlagCountryCode() {
    const language = this.getLanguageByCode(this.mainLanguageId);
    language.completeFlagUrls = language.completeFlagUrls.sort(([, , aIndex], [, , bIndex]) => {
      if (aIndex === 0) {
        return 1;
      }
      if (bIndex === 0) {
        return -1;
      }
      return aIndex - bIndex;
    });
    return this.getLanguageFlagByLocale(language, this.mainLanguageLocaleId);
  }

  private changeMainLanguage(): Promise<void> {
    return this.onBoardingApi.changeMainLanguage(
      {
        languageId: this.mainLanguageId,
        localeId: this.mainLanguageLocaleId,
        countryCode: this.mainFlagCountryCode,
      },
      this.instanceId
    );
  }

  public async finishOnboarding(experiments: TypedExperiments) {
    const onboardingV2Enabled = experiments.enabled('specs.ml.onboardingV2');

    try {
      this.fedopsLogger.interactionStarted('multilingual-onboarding-complete');

      if (experiments.enabled('specs.ml.onboardingMainLangSetup')) {
        await this.initializeMainLanguage();
      }

      if (this.didChangeMainLang) {
        await this.changeMainLanguage();
      }

      if (onboardingV2Enabled || experiments.get('se_multilingualOCTNoServer') === 'new') {
        await this.onBoardingApi.addSecondaryLanguage(
          {
            languageId: this.secondaryLanguageId,
            localeId: this.secondaryLanguageLocaleId,
            countryCode: this.secondaryFlagCountryCode,
            autoTranslate: this.secondaryLanguageAutoTranslate === 'Active' ? 'ON' : 'OFF',
            status: this.secondaryLanguageMakeVisible,
          },
          this.instance,
          this.instanceId,
          experiments
        );
      } else {
        await this.onBoardingApi.addSecondaryLanguage(
          {
            languageId: this.secondaryLanguageId,
            localeId: this.secondaryLanguageLocaleId,
            countryCode: this.secondaryFlagCountryCode,
          },
          this.instance,
          this.instanceId,
          experiments
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    if (
      this.editorSDK.editor.updateAppState &&
      typeof this.editorSDK.editor.updateAppState === 'function'
    ) {
      if (onboardingV2Enabled) {
        const {data} = await this.onBoardingApi.getAllLanguages(this.instance);
        await this.editorSDK.editor.updateAppState(data);
      } else {
        const {data} = await this.onBoardingApi.getLanguagesStatus(this.instanceId);
        await this.editorSDK.editor.updateAppState(data);
      }
    }
  }

  public async initializeMainLanguage() {
    if (this.returningUser) {
      return;
    }
    await this.onBoardingApi.setMainLanguage(
      {
        mainLanguageId: this.mainLanguageId,
        mainLanguageLocaleId: this.mainLanguageLocaleId,
        mainLanguageFlag: this.mainFlagCountryCode,
      },
      this.instanceId
    );

    if (
      this.editorSDK.editor.updateAppState &&
      typeof this.editorSDK.editor.updateAppState === 'function'
    ) {
      await this.updateEditorState();
    }
  }

  private async updateEditorState() {
    const {data} = await this.onBoardingApi.getLanguagesStatus(this.instanceId);
    await this.editorSDK.editor.updateAppState(data);
  }

  public closeModal(value: ModalResolveValues) {
    this.editorSDK.editor.closePanel(this.token, value);
  }

  public updatePanelSize(width: number, height: number) {
    this.editorSDK.editor.updatePanelOptions(this.token, {width, height});
  }

  public updatePanelOptions(newOptions: Partial<IModalPanelOptions>) {
    this.editorSDK.editor.updatePanelOptions(this.token, newOptions);
  }

  public getLocaleIdByLanguageId(languageId: string) {
    const selectedLanguage = this.languagesList.filter(
      (language) => language.code === languageId
    )[0];
    return selectedLanguage.defaultLocale;
  }

  @action
  public getFlagsUrlMapByLanguageId(languageId: string): IFlagUrls {
    const selectedLanguage = this.languagesList.filter(
      (language) => language.code === languageId
    )[0];
    return selectedLanguage.flagUrls;
  }

  getMachineTranslationKey = (languageType: 'main' | 'secondary') => {
    if (languageType === 'main') {
      return this.languagesList.find((language) => language.code === this.mainLanguageId)
        ?.machineTranslationLanguageCode;
    }
    return this.languagesList.find((language) => language.code === this.secondaryLanguageId)
      ?.machineTranslationLanguageCode;
  };

  private getLanguageByCode(languageCode: string) {
    return this.languagesList.find((lang) => lang.code === languageCode);
  }

  public getFlagTupleForLanguage(languageCode: string): TFlagLocaleTuple[] {
    return this.getLanguageByCode(languageCode).completeFlagUrls;
  }

  public getDefaultFlagPairsForLanguage(
    languageCode: string,
    experimentProvider: TypedExperiments
  ): TFlagLocaleTuple {
    return extractDefaultCountryCode(this.languagesList.find((lang) => lang.code === languageCode));
  }

  @action
  public updateMainLanguageId(languageId: string) {
    this.mainLanguageId = languageId;
    this.didChangeMainLang = true;
    if (this.secondaryLanguageId && this.mainLanguageId === this.secondaryLanguageId) {
      this.resetSecondaryLanguage();
    }
  }

  @action
  public updateSecondaryLanguageAutoTranslate(autoTranslate: string) {
    this.secondaryLanguageAutoTranslate = autoTranslate;
  }

  @action
  public updateSecondaryLanguageVisibility(makeVisible: string) {
    this.secondaryLanguageMakeVisible = makeVisible;
  }

  @action
  public updateSecondaryLanguageId(languageId: string) {
    this.secondaryLanguageId = languageId;
  }

  @action
  public updateMainLanguageLocaleId(localeId: string) {
    this.mainLanguageLocaleId = localeId;
    const possibleFlags = this.getFlagsUrlMapByLanguageId(this.mainLanguageId);
    this.mainFlagCountryCode = possibleFlags[localeId];
  }

  @action
  public updateSecondaryLanguageLocaleId(localeId: string) {
    this.secondaryLanguageLocaleId = localeId;
    const possibleFlags = this.getFlagsUrlMapByLanguageId(this.secondaryLanguageId);
    this.secondaryFlagCountryCode = possibleFlags[localeId];
  }

  @action
  public updateMainLanguageLocale(localeId: string, countryCode: string) {
    this.mainLanguageLocaleId = localeId;
    this.mainFlagCountryCode = countryCode;
    this.didChangeMainLang = true;
  }

  @action
  public updateSecondaryLanguageLocale(localeId: string, countryCode: string) {
    this.secondaryLanguageLocaleId = localeId;
    this.secondaryFlagCountryCode = countryCode;
  }

  public async autoTranslate() {
    return this.editorSDK.language.autoTranslate(this.token, {
      languageCode: this.secondaryLanguageId,
      origin: 'ob',
    });
  }

  public static extractInstanceId(instance: string) {
    const instanceWithoutSignature = instance.split('.')[1];
    const instanceStr = atob(instanceWithoutSignature);

    return JSON.parse(instanceStr).instanceId;
  }

  private getLanguageIdByLocale(locale) {
    return this.languagesList.filter((language) => language.code === locale)[0].code;
  }

  private getLanguagesIdList() {
    return this.languagesList.map((language) => ({id: language.code}));
  }

  private resetSecondaryLanguage() {
    this.secondaryLanguageId = '';
    this.secondaryLanguageLocaleId = '';
    this.secondaryFlagCountryCode = '';
    this.secondaryLanguageAutoTranslate = '';
    this.secondaryLanguageMakeVisible = '';
  }

  public continueToSite = (translationMethod: TranslationMethod) => {
    biLogger.onboardingFinish({
      main_lang: this.mainLanguageId,
      secondary_lang: this.secondaryLanguageId,
      main_locale: this.mainLanguageLocaleId,
      secondary_locale: this.secondaryLanguageLocaleId,
      chosen_flag_main_lang: this.mainFlagCountryCode,
      chosen_flag_secondary_lang: this.secondaryFlagCountryCode,
      finish_method: 'start_now',
      translation_method: translationMethod,
    });

    this.closeModal(ModalResolveValues.COMPLETE);
  };

  public setCurrentStepOnWorker = (step: EStep) =>
    setCurrentStepOnWorkerWithEditorSDK(this.editorSDK)(step);
}
