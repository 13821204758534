import {
  INITIALIZE_LANGUAGES_URL,
  SUPPORTED_LANGUAGES_URL,
  SITE_PROPERTIES_SITE_LANGUAGE,
  LANGUAGES_PATH,
  MAIN_LANGUAGE,
  ADD_LANGUAGES_PATH,
  GET_LANGUAGES_PATH
} from './topology';
import {UrlParser} from './url-parser';
import {AxiosStatic, AxiosResponse} from 'axios';
import {ISiteLanguageAndLocalResponse} from '../types/wix-types';
import {TypedExperiments} from '../types/TWixTypes';


export type LanguageId = string;

export interface ISiteLanguageAndLocale {
  languageId: string;
  localeId: string;
  countryCode?: string;
  autoTranslate?: string;
  status?: string;
}

export interface ILanguageStatus {
  code: string;
  locale: string;
  name?: string;
  status?: 'Active' | 'Inactive';
  autoTranslate?: 'ON' | 'OFF';
}

export interface ILanguagesStatus {
  data: {
    originalLanguage: ILanguageStatus[];
    translationLanguages: ILanguageStatus[];
  };
}

export class OnBoardingApi {
  private readonly httpClient: AxiosStatic;

  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  public getLanguages() {
    return this.httpClient.get(SUPPORTED_LANGUAGES_URL);
  }

  public getLanguagesStatus(instanceId: string): Promise<AxiosResponse<ILanguagesStatus>> {
    return this.httpClient.get(LANGUAGES_PATH, {
      params: {'instance-id': instanceId},
    });
  }

  public getAllLanguages(instance: string): Promise<AxiosResponse<ILanguagesStatus>> {
    return this.httpClient.get(GET_LANGUAGES_PATH, {
      params: {'Authorization': instance},
    });
  }


  public setMainLanguage(
    {
      mainLanguageId,
      mainLanguageLocaleId,
      mainLanguageFlag,
    }: {
      mainLanguageId: string;
      mainLanguageLocaleId: string;
      mainLanguageFlag: string;
    },
    instanceId: string
  ) {
    return this.httpClient.post(
      MAIN_LANGUAGE,
      wrapWithDTO({
        code: mainLanguageId,
        locale: mainLanguageLocaleId,
        countryCode: mainLanguageFlag,
      }),
      {
        params: {'instance-id': instanceId},
      }
    );
  }

  async getSiteLanguage(msid: string): Promise<LanguageId> {
    const {data}: {data: ISiteLanguageAndLocalResponse} = await this.httpClient.get(
      `${SITE_PROPERTIES_SITE_LANGUAGE}?fields.paths=language&metasiteId=${msid}`
    );
    return data.properties.language;
  }

  async addSecondaryLanguage(
    {languageId, localeId, countryCode, autoTranslate, status}: ISiteLanguageAndLocale,
    instance: string, instanceId: string, experiments: TypedExperiments
  ): Promise<void> {
    const onboardingV2Enabled = experiments.enabled('specs.ml.onboardingV2');

    if(onboardingV2Enabled || experiments.get('se_multilingualOCTNoServer') === 'new') {
      await this.httpClient.post(
        ADD_LANGUAGES_PATH,{
          id: languageId,
          code: languageId,
          locale: localeId,
          countryCode,
          primary: false,
          status,
          autoTranslate,
        },
        {
          headers: {'Authorization': instance},
        }
      );
    } else {
      await this.httpClient.put(
        LANGUAGES_PATH,
        wrapWithDTO({
          code: languageId,
          locale: localeId,
          flagUrl: countryCode,
        }),
        {
          params: {'instance-id': instanceId},
        }
      );
    }
  }

  async changeMainLanguage(
    {languageId, localeId, countryCode}: ISiteLanguageAndLocale,
    instanceId: string
  ): Promise<void> {
    await this.httpClient.put(
      MAIN_LANGUAGE,
      wrapWithDTO({
        code: languageId,
        locale: localeId,
        countryCode,
      }),
      {
        params: {'instance-id': instanceId},
      }
    );
  }
}

export function wrapWithDTO(data: object) {
  return {
    data,
  };
}
