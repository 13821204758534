import axios from 'axios';
import {wixAxiosConfig} from '@wix/wix-axios-config';

export const configureHttpClient = (origin: string, instance: string) => {
  wixAxiosConfig(axios, {
    baseURL: origin,
  });
  axios.defaults.headers.common.Authorization = instance;
  return axios;
};
